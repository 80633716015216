<template>
  <base-section id="theme-features">
    <base-section-heading title="Caracteristicas Principales">
      
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
            {{feature.desc}}
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      features: [
        {
          title: 'Monitoreo en Vivo',
          icon: 'mdi-cast-connected',
          desc: 'La posición es obtenida y actualizada cada minuto',
        },
        {
          title: 'Bloqueo',
          icon: 'mdi-shield-car',
          desc: 'Habilidad de hacer bloqueo de motor remotamente',
        },
        {
          title: 'Geocerca',
          icon: 'mdi-earth',
          desc: 'Definicion de zonas',
        },
        {
          title: 'Alertas',
          icon: 'mdi-map-marker-alert-outline',
          desc: 'Alertas directamente a tu correo',
        },
        {
          title: 'Usuarios Invitado',
          icon: 'mdi-account-multiple',
          desc: 'Comparte la ubicacion de tus dispositivos a tus compañeros',
        },
        {
          title: 'Reportes',
          icon: 'mdi-file-chart',
          desc: 'Accede al historial de movimientos de hasta 3 meses atras',
        },
        {
          title: 'Sin intermediarios',
          icon: 'mdi-set-center',
          desc: 'Sistema unico y diseñado por nosotro',
        },
        {
          title: 'Personalizaciones',
          icon: 'mdi-cellphone-link',
          desc: 'Podemos realizar adecuaciones a como el cliente necesite',
        },
      ],
    }),
  }
</script>
